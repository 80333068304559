var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-select",
    _vm._g(
      _vm._b(
        {
          attrs: {
            items: _vm.datas,
            label: "Sınav Sonuç Grubu",
            "item-text": "Name",
            "item-value": "Id",
            outlined: "",
            filter: _vm.filterTR,
          },
        },
        "v-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }